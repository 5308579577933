import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
	selector: 'app-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnInit {

	constructor(private loginService: LoginService) { }

	ngOnInit() {
	}

	get IsLogedIn() : boolean{
		return this.loginService.IsAuthenticated();
	}

	get UserName() : string {
		return this.loginService.GetUserName();
	}

	get UserAva() : string {
		return this.loginService.GetUserAva();
	}

	Logout() : void {
		this.loginService.LogOut();
	}

	VkLogin() : void {
		this.loginService.VkLogin();
	}
}
