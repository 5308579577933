import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const BASE_URL_TOKEN = 'BASE_URL';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

constructor(
  @Inject(BASE_URL_TOKEN) private baseUrl: string,
  private http: HttpClient) { }

  getShopingList(date: any){
    const url = `${this.baseUrl}api/Ingredient/shopingList`;
    var request = {date: date}
    return this.http.post(url, request);
  }
}
