import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarWeekComponent } from './calendar/calendar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule } from './material-module.module';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from './services/login.service';
import { HomeComponent } from './home/home.component';
import { ModalMealComponent } from './modal-meal/modal-meal.component';
import { AuthInterceptor } from './Interceptors/AuthInterceptor';
import { UnauthorizeInterceptor } from "./Interceptors/UnauthorizeInterceptor";
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ErrorInterceptor } from './Interceptors/ErrorInterceptor';
import { ModalRecipeComponent } from './modal-recipe/modal-recipe.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RecipesComponent } from './recipes/recipes.component';
import { VkCallbackComponent } from './vk-callback/vk-callback.component';
import { BasementComponent } from './basement/basement.component';
import { ModalRecipeAddComponent } from './modal-recipe-add/modal-recipe-add.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';

@NgModule({
	declarations: [					
		AppComponent,
		CalendarWeekComponent,
		NavMenuComponent,
		LoginComponent,
		HomeComponent,
		ModalMealComponent,
		ModalErrorComponent,
      	ModalRecipeComponent,
      	RecipesComponent,
      	VkCallbackComponent,
      	BasementComponent,
      ModalRecipeAddComponent,
      ShoppingListComponent
   ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModuleModule,
		FormsModule,
		HttpClientModule,
		HttpClientJsonpModule,
		JwtModule.forRoot({
			config: {
			  tokenGetter: tokenGetter,
			  allowedDomains: [".eats-easy.ru"]
			},
		  }),
		ReactiveFormsModule
	],
	providers: [
		{
			provide: 'BASE_URL',
			useValue: 'http://api.eats-easy.ru/'
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizeInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		},
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
