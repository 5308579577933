import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JSONData } from '../models/JSONData';
import * as VKID from '@vkid/sdk';

export const BASE_URL_TOKEN = 'BASE_URL';
export const ACCESS_TOKEN_KEY = "JWT_TOKEN"
export const USER_NAME = "USER_NAME";
export const USER_AVA = "USER_AVA";
export const APP_ID = 51819627;
export const REDIRECT_URL = "http://eats-easy.ru/vk-callback";

export function tokenGetter() {
	return localStorage.getItem(ACCESS_TOKEN_KEY);
}

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	private token: string;

	constructor(
		@Inject(BASE_URL_TOKEN) private baseUrl: string,
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		private router: Router) {
			VKID.Config.set(
				{
					app: APP_ID, 
					redirectUrl: REDIRECT_URL
				});


			this.token = localStorage.getItem(ACCESS_TOKEN_KEY);
		}

	GoogleLogin(): void {
		// this.authService
		// 	.signIn(GoogleLoginProvider.PROVIDER_ID)
		// 	.then((user: any) => {
		// 		if (user) {
		// 			this.http.post<AccessTocken>('api/Accounts/googleLogin', { idToken: user.idToken })
		// 				.subscribe((token: AccessTocken) => {
		// 					this.token = token.accessToken;
		// 					localStorage.setItem(ACCESS_TOKEN_KEY, this.token);
		// 					this.router.navigate(['calendar']);
		// 				});
		// 		}
		// 	});
	}

	VkLogin(): void {
		VKID.Auth.login();
	}

	VkLoginPrepare(payload: string): void{
		const jsonObject: JSONData = JSON.parse(payload);
		localStorage.setItem(USER_NAME, jsonObject.user.first_name + " " + jsonObject.user.last_name);
		localStorage.setItem(USER_AVA, jsonObject.user.avatar);

		this.VkLoginExchangeSilentToken(jsonObject);
	}

	VkLoginExchangeSilentToken(jsonData: JSONData): void {
		const url = `${this.baseUrl}api/Accounts/vkLogin`;

		this.http.post<any>(url, jsonData)
		.subscribe(response => {
			this.token = response.accessToken;
			localStorage.setItem(ACCESS_TOKEN_KEY, this.token);
			this.router.navigate(['calendar']);
		});
	}
 
	IsAuthenticated(): boolean {
		return this.token != null && !this.jwtHelper.isTokenExpired(this.token);
	}

	GetUserName(): string {
		var userName = localStorage.getItem(USER_NAME);
		return `${userName}`;
	}

	GetUserAva(): string {
		var userAva = localStorage.getItem(USER_AVA);
		return `${userAva}`;
	}

	LogOut() : void {
		//this.http.get('api/accounts/logout').subscribe();
		this.token = null;
		localStorage.removeItem(ACCESS_TOKEN_KEY);
		this.router.navigate(['']);
	}
}
