import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CalendarWeekComponent } from "./calendar/calendar.component";
import { RecipesComponent } from './recipes/recipes.component';
import { VkCallbackComponent } from './vk-callback/vk-callback.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'calendar', component: CalendarWeekComponent},
  {path: 'recipes', component: RecipesComponent},
  {path: 'vk-callback', component: VkCallbackComponent},
  {path: 'shopping-list', component: ShoppingListComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
