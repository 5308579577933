import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Month } from '../models/Month';
import { Day } from '../models/Day';
import { Meal } from '../models/Meal';
import { MealService } from "../services/meal.service";
import { startOfMonth, addDays, format } from 'date-fns';

@Component({
  selector: 'app-modal-meal',
  templateUrl: './modal-meal.component.html',
  styleUrls: ['./modal-meal.component.less']
})
export class ModalMealComponent implements OnInit {
  name: string;
  addForPeriod: boolean = false;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ModalMealComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { month: Month, day: Day },
    private mealService: MealService,
  ) {
    const currentDate = new Date();
    this.minDate = startOfMonth(currentDate);
    this.maxDate = addDays(currentDate, 50);
  }

  ngOnInit(): void {

  }

  onAdd() {
    const myDate = new Date();
    var dateFrom = this.addForPeriod ? this.startDate.toDateString() : new Date(myDate.getFullYear(), this.data.month.MonthNumber, this.data.day.Number).toDateString();
    var dateTo = this.addForPeriod ? this.endDate.toDateString() : null;
    let meal = new Meal(this.name, dateFrom, dateTo);
    this.mealService.setMeal(meal).subscribe(
      response => {
        this.dialogRef.close();
        window.location.reload();
      },
      error => {
        console.error('Ошибка запроса:', error);
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  fieldsInvalid(): boolean {
    return !this.name;
  }
}
