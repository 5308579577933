import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {

	constructor(private loginService: LoginService) {
	}

	ngOnInit(): void {
	}

	googleLogin(): void {
		this.loginService.GoogleLogin();
	}

	vkLogin(): void {
		
	}
}
