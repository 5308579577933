<div class="shoplist">
  <mat-chip-listbox class="shoplist-menu" aria-label="Shoplist selection">
    <mat-chip-option selected>Сегодня</mat-chip-option>
    <mat-chip-option>Завтра</mat-chip-option>
    <mat-chip-option>На 3 дня</mat-chip-option>
    <mat-chip-option>На 7 дней</mat-chip-option>
  </mat-chip-listbox>

  <mat-selection-list #shoes>
    <mat-list-option *ngFor="let item of shopingList">
      {{item}}
    </mat-list-option>
  </mat-selection-list>
</div>
