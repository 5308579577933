<mat-dialog-content>
  <mat-form-field class="name-meal">
    <mat-label>Наименование</mat-label>
    <input matInput [(ngModel)]="name" name="name" required>
    <mat-error *ngIf="fieldsInvalid()">Наименование обязательно</mat-error>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="addForPeriod">Добавить за период</mat-checkbox>

  <div *ngIf="addForPeriod">
    <mat-form-field class="date-range">
      <mat-label>Выберите период</mat-label>
      <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
        <input matStartDate placeholder="Начальная дата" [(ngModel)]="startDate" name="startDate">
        <input matEndDate placeholder="Конечная дата" [(ngModel)]="endDate" name="endDate">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onAdd()" [disabled]="fieldsInvalid()">Добавить</button>
  <button mat-button (click)="onClose()">Закрыть</button>
</mat-dialog-actions>