<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Рецепт</mat-label>
    <input type="text"
           placeholder="Выберите рецепт"
           aria-label="Рецепт"
           matInput
           [formControl]="recipeControl"
           [matAutocomplete]="auto"
           [ngClass]="{'error': recipeControl.invalid && recipeControl.touched}">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onRecipeSelected($event.option.value)">
      <mat-option (click)="onCreateRecipe()">Добавить свой рецепт</mat-option>
      <mat-option *ngFor="let recipe of filteredRecipes | async" [value]="recipe">
        {{recipe.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="recipeControl.invalid && recipeControl.touched">
      Выберите рецепт
    </mat-error>
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button mat-button (click)="onAdd()" [disabled]="recipeControl.invalid || !recipeControl.value">Добавить</button>
  <button mat-button (click)="onClose()">Закрыть</button>
</mat-dialog-actions>
