export class Meal {
	Description: string;
	DateFrom: string;
	DateTo: string;

	constructor(description: string, dateFrom: string, dateTo: string)
	{
		this.Description = description;
		this.DateFrom = dateFrom;
		this.DateTo = dateTo;
	}
}
