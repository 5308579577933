import { Injectable } from '@angular/core';

export const ACCESS_TOKEN_KEY = "JWT_TOKEN"

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string | null;
  
constructor() { 
  this.token = localStorage.getItem(ACCESS_TOKEN_KEY);
}

  setToken(token: string) {
    this.token = token;
    localStorage.setItem('access_token', token);
  }

  getToken(): string | null {
    return this.token;
  }
}
