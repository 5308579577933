import { Component, OnInit } from '@angular/core';
import { IngredientService } from '../services/ingredient.service';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.less']
})
export class ShoppingListComponent implements OnInit {
  shopingList: string[] = [];
  constructor(public ingredientService : IngredientService) { }

  async ngOnInit() {
    const today = new Date();
    await this.loadShopingListAsync(today);
  }

  async loadShopingListAsync(date: Date) {
    try {
      const data = await this.ingredientService.getShopingList(date).toPromise();
      this.shopingList = data as any[];
    }
    catch (error) {
      console.error('Произошла ошибка при загрузке данных:', error);
    }
  }
}
