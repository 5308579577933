import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MealRecipeRequest } from '../models/requests/MealRecipeRequest';

export const BASE_URL_TOKEN = 'BASE_URL';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

constructor(
  @Inject(BASE_URL_TOKEN) private baseUrl: string,
  private http: HttpClient) { }

  addRecipe(data: any){
    const url = `${this.baseUrl}api/Recipe`;
    return this.http.put(url, data);
  }

  getRecipesForMeal(id: string){
    const url = `${this.baseUrl}api/Meal/recipe?id=${id}`;
    return this.http.get(url);
  }

  getAllRecipe(){
    const url = `${this.baseUrl}api/Recipe`;
    return this.http.get(url);
  }

  getAllIngredients(){
    const url = `${this.baseUrl}api/Ingredient`;
    return this.http.get(url);
  }

  addMealRecipe(mealId: string, recipeId: string){
    const url = `${this.baseUrl}api/Meal/addrecipe`;
    var request = new MealRecipeRequest();
    request.mealId = mealId;
    request.recipeId = recipeId;
    
    return this.http.post(url, request);
  }
}
