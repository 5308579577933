import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const BASE_URL_TOKEN = 'BASE_URL';

@Injectable({
  providedIn: 'root'
})
export class MealService {


constructor(
  @Inject(BASE_URL_TOKEN) private baseUrl: string,
  private http: HttpClient) {}

  getMeals() {
    const url = `${this.baseUrl}api/Meal`;
    return this.http.get(url);
  }

  setMeal(meal: any) {
    const url = `${this.baseUrl}api/Meal`;
    return this.http.post(url, meal);
  }

  removeMeal(id: string){
    const url = `${this.baseUrl}api/Meal?id=${id}`;
    return this.http.delete(url);
  }
}
