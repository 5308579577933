<div class="add-recipe-user">
  <h1 mat-dialog-title>Добавление рецепта</h1>
  <mat-dialog-content>
    <form>
      <mat-form-field class="add-recipe-user-name">
        <mat-label>Название рецепта</mat-label>
        <input matInput [(ngModel)]="name" name="name" required>
        <mat-error *ngIf="fieldsInvalid()">Наименование обязательно</mat-error>
      </mat-form-field>
      
      <mat-form-field class="add-recipe-user-description">
        <mat-label>Описание рецепта</mat-label>
        <textarea matInput rows="4" [(ngModel)]="description" name="description" required></textarea>
        <mat-error *ngIf="fieldsInvalid()">Описание обязательно</mat-error>
      </mat-form-field>

      <mat-form-field class="chip-list-ingredients">
        <mat-label>Выбранные ингредиенты</mat-label>
        <mat-chip-grid #chipGrid aria-label="Ingredients selection">
          <mat-chip-row *ngFor="let selectedIngredient of selectedIngredients" (removed)="removeIngredient(selectedIngredient)">
            {{selectedIngredient.name}}
            <button matChipRemove [attr.aria-label]="'remove ' + selectedIngredient">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>

        <input placeholder="Найти ингредиенты..." #ingredientSearchInput [formControl]="ingredientCtrl"
          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addIngredient($event)"/>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedIngredient($event)">
          <mat-option *ngFor="let ingredient of filteredIngredients | async" [value]="ingredient">
            {{ingredient.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="fieldsInvalid()">Нужно выбрать ингрединты</mat-error>
      </mat-form-field>
      
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onAdd()" [disabled]="fieldsInvalid()">Добавить</button>
    <button mat-button (click)="onClose()">Закрыть</button>
  </mat-dialog-actions>
</div>


