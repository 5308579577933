import { Day } from "./Day";

export class Month {
	MonthNumber: number;
	MonthName: string;
	Days: Day[];

	constructor(monthNumber: number, monthName: string) {
		this.MonthNumber = monthNumber;
		this.MonthName = monthName;
	}

	AddDay(newDay: Day){
		this.Days.push(newDay);
	}

	Clear()
	{
		this.Days = [];
	}
}
