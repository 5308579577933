import { AfterViewInit, AfterViewChecked, ElementRef, ViewChild, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Month } from '../models/Month';
import { Day } from '../models/Day';
import { Dish } from '../models/Dish';
import { WeekDays } from '../models/WeekDays';
import { Meal } from '../models/Meal';

import { CalendarService } from '../services/calendar.service';
import { MealService } from "../services/meal.service";
import { RecipeService } from '../services/recipe.service';

import { ModalMealComponent } from "../modal-meal/modal-meal.component";
import { ModalRecipeComponent } from '../modal-recipe/modal-recipe.component';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.less'],
	providers: [CalendarService]
})
export class CalendarWeekComponent implements OnInit, AfterViewInit, AfterViewChecked {
	private isFirstScroll = true;
	loader = false;
	month: Month;
	months: Month[];
	weekdays: WeekDays[];

	@ViewChild('todayCell', { static: false }) todayCell: ElementRef;

	constructor(
		public dialog: MatDialog,
		private calendarService: CalendarService,
		private mealService: MealService,
		private recipeService: RecipeService) {

		this.months = [
			new Month(0, 'Январь'),
			new Month(1, 'Февраль'),
			new Month(2, 'Март'),
			new Month(3, 'Апрель'),
			new Month(4, 'Май'),
			new Month(5, 'Июнь'),
			new Month(6, 'Июль'),
			new Month(7, 'Август'),
			new Month(8, 'Сентябрь'),
			new Month(9, 'Октябрь'),
			new Month(10, 'Ноябрь'),
			new Month(11, 'Декабрь'),
		];

		this.weekdays = [
			new WeekDays('Пн', false),
			new WeekDays('Вт', false),
			new WeekDays('Ср', false),
			new WeekDays('Чт', false),
			new WeekDays('Пт', false),
			new WeekDays('Сб', true),
			new WeekDays('Вс', true)
		];
	}

	ngOnInit() {
		this.mealService.getMeals().subscribe((meals: Meal[]) => {
			const myDate = new Date();
			const currMonth = this.months.find(m => m.MonthNumber === myDate.getMonth());
			this.fillMonthsDay(myDate.getFullYear(), currMonth, meals);
		});
	}

	ngAfterViewInit(): void {
		this.scrollToToday();
	}

	ngAfterViewChecked(): void {
		this.scrollToToday();
	  }

	scrollToToday(): void {
		if (this.isFirstScroll) {
			const todayElement = document.querySelector('.today') as HTMLElement;
			if (todayElement) {
				todayElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				this.isFirstScroll = false;
			}
		}
	}

	isToday(day: any): boolean {
		const today = new Date();
		return day.Number !== 0 && day.Number === today.getDate();
	}

	loadRecipesForMeal(event: Event, meal: any): void {
		if ((event.target as HTMLElement).classList.contains('day-meal-block')) {
			if (meal && !meal.showRecipes) {
				this.recipeService.getRecipesForMeal(meal.id).subscribe(
				  (data) => {
					meal.mealRecipes = data;
				  },
				  (error) => {
					console.error('Error loading recipes:', error);
				  }
				);
			}
			meal.showRecipes = !meal.showRecipes;
		}
	}

	setMeal(month: Month, day: Day) {
		this.openModalMeal(month, day);
	}

	selectRecipe(meal: any){
		this.openModalRecipe(meal);
	}

	removeMeal(meal: any){
		this.mealService.removeMeal(meal.id).subscribe(
			response => {
			  window.location.reload();
			},
			error => {
			  console.error('Ошибка запроса:', error);
			}
		);
	}

	private fillMonthsDay(year: number, month: Month, meals: any) {
		month.Clear();
		this.setStartViewCalendar(year, month);

		for (let i = 0; i < this.getNumberDaysInMonth(year, month.MonthNumber); i++) {
			const targetDate = new Date(year, month.MonthNumber, i + 1);
			const filterMeals = meals.filter(m => {
				const mealDate = new Date(m.date);
				return mealDate.getFullYear() === targetDate.getFullYear() &&
						mealDate.getMonth() === targetDate.getMonth() &&
						mealDate.getDate() === targetDate.getDate();
			  });
			const day = new Day(i + 1, filterMeals);
			month.AddDay(day);
		}

		this.month = month;
	}

	private getNumberDaysInMonth(year: number, month: number): number {
		return new Date(year, month, 0).getDate();
	}

	private setStartViewCalendar(year: number, month: Month): void {
		const startDayOfWeek = new Date(year, month.MonthNumber, 0).getDay();
		for (let i = 0; i < startDayOfWeek; i++){
			month.AddDay(new Day(0, Dish[0]));
		}
	}

	private openModalMeal(month: Month, day: Day): void {
		const dialogRef = this.dialog.open(ModalMealComponent, { data: { month: month, day: day }});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	}

	private openModalRecipe(meal: any): void {
		const dialogRef = this.dialog.open(ModalRecipeComponent, { data: { meal: meal}});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	}
}
