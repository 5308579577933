import { Component, OnInit, ElementRef, ViewChild, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RecipeService } from '../services/recipe.service';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ModalRecipeComponent } from '../modal-recipe/modal-recipe.component';
@Component({
  selector: 'app-modal-recipe-add',
  templateUrl: './modal-recipe-add.component.html',
  styleUrls: ['./modal-recipe-add.component.less']
})
export class ModalRecipeAddComponent implements OnInit {
  meal: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  ingredientCtrl = new FormControl('');
  @ViewChild('ingredientSearchInput') ingredientSearchInput: ElementRef<HTMLInputElement>;
  announcer = inject(LiveAnnouncer);
  name: string;
  description: string;
  ingredientsList: any[] = [];
  filteredIngredients: Observable<string[]>;
  selectedIngredients: any[] = [];
  searchControl = new UntypedFormControl('');

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { meal: any },
    public dialogRef: MatDialogRef<ModalRecipeAddComponent>,
    public recipeService : RecipeService) {
      this.meal = data.meal;
    }

  async ngOnInit() {
    await this.loadIngredientsAsync();

    this.filteredIngredients = this.ingredientCtrl.valueChanges.pipe(
      startWith(null),
      map((ingredient: string | null) => (ingredient ? this._filter(ingredient) : this.ingredientsList.slice())),
    );
  }

  async loadIngredientsAsync() {
    try {
      const data = await this.recipeService.getAllIngredients().toPromise();
      this.ingredientsList = data as any[];
    }
    catch (error) {
      console.error('Произошла ошибка при загрузке данных:', error);
    }
  }

  onAdd() {
    var data = {
      name: this.name,
      description: this.description,
      ingredients: this.selectedIngredients,
    };

    this.recipeService.addRecipe(data).subscribe(
      response => {
        this.dialogRef.close();
        //window.location.reload();
        this.openModalRecipe(this.meal);
      },
      error => {
        console.error('Ошибка запроса:', error);
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private _filter(value: any): string[] {
    let filterValue = '';

    if (typeof value === 'object' && value !== null) {
      filterValue = value.name.toLowerCase();
    } else if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }

    return this.ingredientsList.filter(ingredient => ingredient.name.toLowerCase().includes(filterValue));
  }

  private openModalRecipe(meal: any): void {
		const dialogRef = this.dialog.open(ModalRecipeComponent, { data: { meal: meal}});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});
	}

  addIngredient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.selectedIngredients.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.ingredientCtrl.setValue(null);
  }

  removeIngredient(fruit: string): void {
    const index = this.selectedIngredients.indexOf(fruit);

    if (index >= 0) {
      this.selectedIngredients.splice(index, 1);

      this.announcer.announce(`Removed ${fruit}`);
    }
  }

  selectedIngredient(event: MatAutocompleteSelectedEvent): void {
    this.selectedIngredients.push(event.option.value);
    this.ingredientSearchInput.nativeElement.value = '';
    this.ingredientCtrl.setValue(null);
  }

  fieldsInvalid(): boolean {
    return !this.name || !this.description || this.selectedIngredients.length == 0;
  }
}
