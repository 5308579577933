import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-vk-callback',
  templateUrl: './vk-callback.component.html',
  styleUrls: ['./vk-callback.component.less']
})
export class VkCallbackComponent implements OnInit {

  token: string;

  constructor(private route: ActivatedRoute, private loginService: LoginService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.loginService.VkLoginPrepare(params['payload']);   
    });
  }
}
