<mat-toolbar class="destop-menu">
  <div class="nav-menu-btn">
    <a mat-button href="" routerLinkActive="active">Главная</a>
  </div>
  <div class="nav-menu-btn" *ngIf='IsLogedIn'>
    <a mat-button href="/calendar" routerLinkActive="active">Календарь</a>
  </div>
  <div class="nav-menu-btn" *ngIf='IsLogedIn'>
    <a mat-button href="/recipes" routerLinkActive="active">Рецепты</a>
  </div>
  <div class="nav-menu-btn" *ngIf='IsLogedIn'>
    <a mat-button href="/shopping-list" routerLinkActive="active">Список покупок</a>
  </div>
  <div class="nav-menu-btn right-align">
    <button id="VKIDSDKAuthButton" class="VkIdWebSdk__button VkIdWebSdk__button_reset" *ngIf='!IsLogedIn' (click)="VkLogin()">
      <div class="VkIdWebSdk__button_container">
        <div class="VkIdWebSdk__button_icon">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54648 4.54648C3 6.09295 3 8.58197 3 13.56V14.44C3 19.418 3 21.907 4.54648 23.4535C6.09295 25 8.58197 25 13.56 25H14.44C19.418 25 21.907 25 23.4535 23.4535C25 21.907 25
            19.418 25 14.44V13.56C25 8.58197 25 6.09295 23.4535 4.54648C21.907 3 19.418 3 14.44 3H13.56C8.58197 3 6.09295 3 4.54648 4.54648ZM6.79999 10.15C6.91798 15.8728 9.92951 19.31 14.8932 19.31H15.1812V16.05C16.989 16.2332 18.3371 
            17.5682 18.8875 19.31H21.4939C20.7869 16.7044 18.9535 15.2604 17.8141 14.71C18.9526 14.0293 20.5641 12.3893 20.9436 10.15H18.5722C18.0747 11.971 16.5945 13.6233 15.1803 13.78V10.15H12.7711V16.5C11.305 16.1337 9.39237 14.3538 9.314 10.15H6.79999Z" fill="white"/>
          </svg>
        </div>
        <div class="VkIdWebSdk__button_text">Войти через VK</div>
      </div>
    </button>

    <button id="VKIDSDKAuthButton" class="VkIdWebSdk__button VkIdWebSdk__button_reset" *ngIf='IsLogedIn' (click)="Logout()">
      <div class="VkIdWebSdk__button_container">
        <div class="VkIdWebSdk__button_icon">
          <img class="nav-menu-user-ava" width="28" height="28" src="{{ UserAva }}">
        </div>
        <div class="VkIdWebSdk__button_text">{{ UserName }}</div>
      </div>
    </button>
  </div>
</mat-toolbar>

<mat-toolbar class="mobile-menu">

  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="nav-menu-btn right-align">
    <button id="VKIDSDKAuthButton" class="VkIdWebSdk__button VkIdWebSdk__button_reset" *ngIf='!IsLogedIn' (click)="VkLogin()">
      <div class="VkIdWebSdk__button_container">
        <div class="VkIdWebSdk__button_icon">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54648 4.54648C3 6.09295 3 8.58197 3 13.56V14.44C3 19.418 3 21.907 4.54648 23.4535C6.09295 25 8.58197 25 13.56 25H14.44C19.418 25 21.907 25 23.4535 23.4535C25 21.907 25
            19.418 25 14.44V13.56C25 8.58197 25 6.09295 23.4535 4.54648C21.907 3 19.418 3 14.44 3H13.56C8.58197 3 6.09295 3 4.54648 4.54648ZM6.79999 10.15C6.91798 15.8728 9.92951 19.31 14.8932 19.31H15.1812V16.05C16.989 16.2332 18.3371 
            17.5682 18.8875 19.31H21.4939C20.7869 16.7044 18.9535 15.2604 17.8141 14.71C18.9526 14.0293 20.5641 12.3893 20.9436 10.15H18.5722C18.0747 11.971 16.5945 13.6233 15.1803 13.78V10.15H12.7711V16.5C11.305 16.1337 9.39237 14.3538 9.314 10.15H6.79999Z" fill="white"/>
          </svg>
        </div>
        <div class="VkIdWebSdk__button_text">Войти через VK</div>
      </div>
    </button>

    <button id="VKIDSDKAuthButton" class="VkIdWebSdk__button VkIdWebSdk__button_reset" *ngIf='IsLogedIn' (click)="Logout()">
      <div class="VkIdWebSdk__button_container">
        <div class="VkIdWebSdk__button_icon">
          <img class="nav-menu-user-ava" width="28" height="28" src="{{ UserAva }}">
        </div>
        <div class="VkIdWebSdk__button_text">{{ UserName }}</div>
      </div>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <a mat-menu-item href="" routerLinkActive="active"><mat-icon>home</mat-icon>Главная</a>
    <a mat-menu-item href="/calendar" *ngIf='IsLogedIn' routerLinkActive="active">Календарь</a>
    <a mat-menu-item href="/recipes" *ngIf='IsLogedIn' routerLinkActive="active">Рецепты</a>
    <a mat-menu-item href="/shopping-list" routerLinkActive="active">Список покупок</a>
  </mat-menu>
</mat-toolbar>