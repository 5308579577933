import { Ingredient } from './ingredient';
export class Dish {
    id: number;
    name: string;
    ingredients: Ingredient[];
	date: Date;

    constructor(id: number, name: string, ingredients: Ingredient[]) {
        this.id = id;
        this.name = name;
        this.ingredients = ingredients;
		// TODO dish.Date
    }
}
