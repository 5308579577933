import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Month } from '../models/Month';

export const BASE_URL_TOKEN = 'BASE_URL';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

constructor(
  @Inject(BASE_URL_TOKEN) private baseUrl: string,
  private http: HttpClient) { }
}
