import { Meal } from './../models/Meal';
export class Day {
	Number: number;
	Meals: Meal[];

	constructor(number: number, meals: Meal[])
	{
		this.Number = number;
		this.Meals = meals;
	}
}