export class WeekDays {
	Name: string;

	Weekend: boolean;

	constructor(name: string, weekend: boolean)
	{
		this.Name = name;
		this.Weekend = weekend;
	}
}