import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import {UntypedFormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { RecipeService } from '../services/recipe.service';
import { Router } from '@angular/router';
import { ModalRecipeAddComponent } from '../modal-recipe-add/modal-recipe-add.component';

@Component({
  selector: 'app-modal-recipe',
  templateUrl: './modal-recipe.component.html',
  styleUrls: ['./modal-recipe.component.less']
})
export class ModalRecipeComponent implements OnInit {
  meal: any;
  selectedRecipe: any;
  recipeControl = new UntypedFormControl('');
  recipes: any[] = [];
  filteredRecipes: Observable<string[]>;

  constructor(
    public dialog: MatDialog,
    public recipeService : RecipeService,
    public dialogRef: MatDialogRef<ModalRecipeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { meal: any },
    private router: Router) {
      this.meal = data.meal;
    }

  async ngOnInit() {
    await this.loadRecipesAsync();

    this.filteredRecipes = this.recipeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  async loadRecipesAsync() {
    try {
      const data = await this.recipeService.getAllRecipe().toPromise();
      this.recipes = data as any[];
    }
    catch (error) {
      console.error('Произошла ошибка при загрузке данных:', error);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAdd(){
    this.recipeService.addMealRecipe(this.meal.id, this.selectedRecipe.id).subscribe(
      response => {
        this.dialogRef.close();
        window.location.reload();
      },
      error => {
        console.error('Ошибка запроса:', error);
      }
    );
  }

  onRecipeSelected(selectedRecipe: any) {
    this.selectedRecipe = selectedRecipe;
  }

  displayFn(recipe: any): string {
    return recipe ? recipe.name : '';
  }

  onCreateRecipe() {
    this.openModalRecipeAdd(this.meal);
    this.dialogRef.close();
  }

  private _filter(value: any): string[] {
    let filterValue = '';

    if (typeof value === 'object' && value !== null) {
      filterValue = value.name.toLowerCase();
    } else if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    }

    return this.recipes.filter(recipe => recipe.name.toLowerCase().includes(filterValue));
  }

  private openModalRecipeAdd(meal: any): void {
		this.dialog.open(ModalRecipeAddComponent, { data: { meal: meal}});
	}
}
