<div class="calendar">
    <div class="days-week">
        <div class="day-week" *ngFor="let weekDay of weekdays" [ngClass]="{'day-week-weekend': weekDay.Weekend}">
            <div class="day-week-name">{{ weekDay.Name }}</div>
        </div>
    </div>

    <div class="days-in-month" *ngIf="month && month.Days">
        <div class="day-month" *ngFor="let day of month.Days; index as i" [ngClass]="{'disable-day': day.Number === 0, 'today': isToday(day)}" #todayCell>
            <div class="day-header">
                <div *ngIf="day.Number != 0" class="number-day">
                    {{ day.Number }}
                </div>
            </div>
            <div class="day-content">
                <div class="day-meals">
                    <div id="{{meal.id}}" class="day-meal" *ngFor="let meal of day.Meals" (click)="loadRecipesForMeal($event, meal)">
                        <div class="day-meal-block">
                            <label>{{meal.description}}</label>
                            <div class="day-meal-actions">
                                <div class="meal-recipes-action-add action" matTooltip="Добавить рецепт" (click)="selectRecipe(meal)"></div>
                                <div class="meal-action-edit action" matTooltip="Переименовать"></div>
                                <div class="meal-action-delete action" matTooltip="Удалить" (click)="removeMeal(meal)"></div>
                            </div>
                        </div>
                        <div class="day-meal-recipes" *ngIf="meal.showRecipes">
                            <div *ngFor="let mealRecipes of meal.mealRecipes">
                                {{ mealRecipes.recipe.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="day-content-image" (click)="setMeal(month, day)" matTooltip="Добавить прием пищи"></div>
        </div>
    </div>

    <div class="wait" *ngIf="loader">
        <mat-spinner></mat-spinner>
    </div>
</div>


